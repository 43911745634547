<template>
  <div class="container">
    <div class="payment-layout row checkout-step">
      <div class="col-md-7">
        <div id="paymentForm">
          <div class="main-heading">
            <h1>Book and Pay for Your Accommodation</h1>
          </div>
          <div class="act-fast">
            <div class="act-fast-wrapper">
              <span class="SVGIcon SVGIcon--24px SVGIcon--inline">
                <svg data-id="SVG_ALARM__24" focusable="false" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor"
                    d="M4.37 6.274l1.5-1.5M18.13 4.774l1.5 1.5M14.809 9.53l-2.876 2.874M9.146 12.404H12M18 19.318l-1-1M6.781 18.318l-1 1">
                  </path>
                  <path stroke="currentColor" d="M5.086 12.404a6.914 6.914 0 1113.829.003 6.914 6.914 0 01-13.829-.003z"
                    clip-rule="evenodd"></path>
                  <path fill="currentColor" stroke="none" fill-rule="evenodd"
                    d="M10.75 12.404a1.251 1.251 0 012.5 0 1.25 1.25 0 01-2.5 0z" clip-rule="evenodd"></path>
                </svg>
              </span>
              <span class="inline">
                <strong>Act fast!</strong> Price and availability may change.
              </span>
            </div>
            <div class="cancellation-tooltip" v-if="$route.params.parrefund">
              <span class="SVGIcon SVGIcon--24px SVGIcon--inline">
                <svg data-id="SVG_DELAY_REFUND_COLOR__24" focusable="false" xmlns="http://www.w3.org/2000/svg"
                  width="24" height="24" stroke="none" fill="none" viewBox="0 0 24 24">
                  <path stroke="#292929" d="M8.625 8.597H4.882V4.854"></path>
                  <path stroke="#292929"
                    d="M12.375 19.5c4.143 0 7.5-3.357 7.5-7.5A7.499 7.499 0 007.071 6.696L5.179 8.59"></path>
                  <path stroke="none" fill="#292929" fill-rule="evenodd"
                    d="M4.875 12.753c-.101 0-.2-.02-.29-.06a.658.658 0 01-.24-.17.73.73 0 01-.16-.81c.03-.09.089-.17.16-.24.07-.07.15-.13.24-.17.189-.07.39-.07.58 0a.807.807 0 01.399.41c.04.09.061.19.061.29 0 .2-.08.39-.22.52a.722.722 0 01-.53.23zM5.225 16.123h.01a.738.738 0 01.27-1.02c.35-.21.81-.09 1.02.27a.75.75 0 01-.65 1.13.758.758 0 01-.65-.38zm3.02 3.02a.742.742 0 01-.27-1.02c.2-.36.66-.49 1.02-.28a.752.752 0 01-.38 1.4.708.708 0 01-.37-.1z"
                    clip-rule="evenodd"></path>
                  <path stroke="#A5C99A"
                    d="M13.81 9.13h-1.944a1.283 1.283 0 00-.478 2.476l1.974.789a1.284 1.284 0 01-.48 2.474h-1.941M12.375 9.13V7.697M12.375 16.304v-1.435">
                  </path>
                </svg>
              </span>
              <span>
                <span>Partial refund if you cancel by</span>
                <div class="" role="button" tabindex="0"
                  style="display: inline-block; outline: 0px; position: relative;">
                  <a> {{ $route.params.prefund }} </a>
                </div>
              </span>
            </div>
          </div>
          <section class="step-section">
            <div class="step-section__wrapper">
              <h3 class="guest-header">Enter contact information</h3>
              <div class="contact-details checkout-step-section">
                <div class="mandatory-field-info">Required fields</div>
              </div>
              <div class="row information-form mb-4">
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" v-model="first_name" class="form-control" placeholder="First Name *">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" v-model="last_name" class="form-control" placeholder="Last Name *">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="email" v-model="email" class="form-control" placeholder="Email *">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <!-- <input type="tel" class="form-control" placeholder="Phone Number *"> -->
                    <VuePhoneNumberInput v-model="phone" />
                  </div>
                </div>
              </div>
              <h3 class="guest-header">Billing address</h3>
              <div class="row information-form">
                <div class="col-md-12">
                  <div class="form-group">
                    <input type="text" v-model="country" class="form-control" placeholder="Country *">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" v-model="zip_code" class="form-control" placeholder="Zip Code *">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" v-model="state" class="form-control" placeholder="State *">
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="step-section">
            <div class="step-section__wrapper">
              <div class="row">
                <div class="col-lg-8">
                  <h3 class="guest-header">Credit Card Information</h3>
                </div>
                <div class="col-lg-4">
                  <ul class="card-list">
                    <li v-for="brand in cardBrands" :key="brand.name" class="card-item">
                      <img :src="brand.image" :alt="brand.name" class="card-image" />
                    </li>
                  </ul>
                </div>
              </div>
              <div class="contact-details checkout-step-section">
                <div class="mandatory-field-info">Required fields</div>
              </div>
              <div class="row information-form mb-4">
                <div class="col-md-12">
                  <div class="form-group">
                    <!-- <input type="text" class="form-control" placeholder="Card Holder Name *"> -->
                    <input type="text" id="cardHolderName" class="form-control mb-3"
                      :placeholder="$t('payment.card_holder_name')" v-model="cardHolderName">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <div id="cardElement" class="form-control"></div>
                    <div id="cardErrors" role="alert" v-if="paymentError"
                      style="margin-top: -15px;margin-left: 15px;color: red;">{{ paymentError }}</div>
                    <!-- <input type="text" class="form-control" placeholder="Card Number *"> -->
                  </div>
                </div>
                <!-- <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Expiration MM/YY *">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="tel" class="form-control" placeholder="Security code *">
                  </div>
                </div> -->
              </div>
            </div>
          </section>
          <section class="step-section mt-2">
            <div class="step-section__wrapper">
              <div class="message-to-host">
                <h3>Include a message for the host</h3><span>Have comments, questions or special requests?</span>
                <div class="premier-host-badge">
                  <div class="" role="button" tabindex="0" style="display: block; outline: 0px; position: relative;">
                    Premier
                    Host</div>
                </div>
                <div class="message-to-host__form">
                  <div class="form-group">
                    <textarea id="message" aria-label="Your message... (optional)" maxlength="3000" rows="3"
                      class="FormTextArea__textarea form-control" placeholder="Your message... (optional)"
                      spellcheck="false"></textarea>
                  </div>
                </div>
                <p>We'll send your message to the host, but cannot guarantee any special requests. Please feel free to
                  contact them after booking to confirm.</p>
              </div>
            </div>
          </section>
          <!-- <section class="step-section mt-2">
            <div class="step-section__wrapper">
              <h3 class="title">Important information</h3>
              <ul style="list-style: disc;">
                <li><strong>Cancellation Policy: </strong><span key="0">Your booking will not qualify for a refund
                    based on your trip dates.</span></li>
                <li><strong>Age restrictions:</strong> Minimum age to rent is 18.</li>
                <li class="house-rules">
                  <div class="house-rules"><a href="#">Review the house rules.</a>
                    <div></div>
                  </div>
                </li>
              </ul>
            </div>
          </section> -->
          <div class="contact-details-container-outer">
            <div class="contact-details-container-outer__agreement">
              By clicking 'Agree &amp; continue' you are agreeing to our
              <a href="#" target="_blank" class="js-eventTracking" data-event-action="terms conditions">Terms and
                Conditions</a>,
              <a href="#" target="_blank" class="js-eventTracking" data-event-action="privacy policy">Privacy
                Policy</a>,
              and to receive booking-related texts. Standard messaging rates may apply.
            </div>
          </div>
          <div class="cta-panel">
            <div class="flex text-center pay-button">
              <!-- <button class="btn btn-primary mt-3" @click="processPayment">{{ $t('payment.pay') }}</button> -->
              <button class="btn btn-primary cta-button  btn-lg" data-at-id="contact-continue-button-standard"
                type="button" @click="processPayment">
                <span class="btn__label">Book &amp; Pay Now</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="sidebar-container">
          <div class="listing-info-container">
            <div class="carousel-sec">
              <b-carousel id="carousel-1" v-model="slide[index]" :interval="0" controls indicators background="#ababab"
                img-width="1024" img-height="480" style="text-shadow: 1px 1px 2px #333;"
                @sliding-start="onSlideStart(index)" @sliding-end="onSlideEnd(index)">

                <b-carousel-slide v-for="(property, index) in photos" :key="index">
                  <!-- Wrap the image in a link with Fancybox attributes -->
                  <a :href="property.path" data-fancybox="gallery">
                    <img :src="property.path" alt="image" />
                  </a>
                </b-carousel-slide>
              </b-carousel>
            </div>
            <div class="listing-property-info">
              <div class="property-details">
                <div class="" role="button" tabindex="0" style="display: block; outline: 0px; position: relative;">
                  <h3 class="property-details__headline truncate">{{ property.property_type }}</h3>
                </div><span class="property-details__address">{{ property.city }}, {{ property.state }}, {{
                  property.country }}</span>
              </div>
            </div>
            <div class="payment-calandar">
              <div class="date-picker">
                <date-range-picker v-model="dateRange" :format="format" :min-date="today">
                  <template v-slot:input="picker" style="min-width: 350px;">
                    <div class="availability-boxes">
                      <div class="box">
                        <span>Check In</span>
                        <p>{{ sdate }}</p>
                      </div>
                      <div class="box">
                        <span>Check Out</span>
                        <p>{{ edate }}</p>
                      </div>
                    </div>
                  </template> </date-range-picker>
              </div>
            </div>
            <div class="availability-boxes">
              <!-- <div class="box" @click="showPicker = true">
                <span>Check In</span>
                <p>{{ sdate }}</p>
              </div>
              <div class="box" @click="showPicker = true">
                <span>Check Out</span>
                <p>{{ edate }}</p>
              </div> -->

              <div class="box" @click="togglePopup">
                <span>Guests</span>
                <p>{{ guestNumber }} Guests</p>
                <!-- <p>{{ property.max_guests }} Guests</p> -->
              </div>
              <div class="popup-guest" v-if="isPopupVisible">
                <div class="guest-content">
                  <div class="guest-col">
                    <h4 style="margin-right: 10px;">Adults</h4>
                  </div>
                  <div class="guest-col">
                    <template>
                      <div class="quantity">
                        <b-input-group>
                          <b-input-group-prepend>
                            <b-btn variant="info" @click="decrement">-</b-btn>
                          </b-input-group-prepend>

                          <b-form-input type="number" min="0.00" v-model="guestNumber"></b-form-input>

                          <b-input-group-append>
                            <b-btn variant="info" @click="increment">+</b-btn>
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="guest-content" v-if="property.children !== 'Not Allowd'">
                  <div class="guest-col">
                    <h4 style="margin-right: 10px;">Childs</h4>
                  </div>
                  <div class="guest-col">
                    <template>
                      <div class="quantity">
                        <b-input-group>
                          <b-input-group-prepend>
                            <b-btn variant="info" @click="childDecrement">-</b-btn>
                          </b-input-group-prepend>

                          <b-form-input type="number" min="0.00" :value="childQuantity"></b-form-input>

                          <b-input-group-append>
                            <b-btn variant="info" @click="childIncrement">+</b-btn>
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="guest-content" v-if="property.Pets !== 'Not Allowd'">
                  <div class="guest-col">
                    <h4 style="margin-right: 10px;">Pets</h4>
                  </div>
                  <div class="guest-col">
                    <template>
                      <div class="quantity">
                        <b-input-group>
                          <b-input-group-prepend>
                            <b-btn variant="info" @click="petDecrement">-</b-btn>
                          </b-input-group-prepend>

                          <b-form-input type="number" min="0.00" :value="petQuantity"></b-form-input>

                          <b-input-group-append>
                            <b-btn variant="info" @click="petIncrement">+</b-btn>
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="availability-listing">
              <div class="price-listing">
                <div class="lisitng-content"><span>{{ $route.params.currency }} {{ $route.params.pfprice }} x {{ ppday
                    }}
                    night</span> <span>{{ $route.params.currency }} {{ tprice }}
                  </span></div>
                <div class="lisitng-content"><span>Host Fees</span> <span>{{ $route.params.currency }} {{
                  hostFees.toFixed(0) }}</span></div>
                <div class="lisitng-content"><span>Service Fee</span> <span>{{ $route.params.currency }}
                    {{ servicefee.toFixed(0) }}</span></div>
                <div class="lisitng-content"><span>Tax</span> <span>{{ $route.params.currency }}
                    {{ taxfee.toFixed(0) }}</span></div>
                <div class="lisitng-content total-pricing">
                  <h3>Total</h3>
                  <h3>{{ $route.params.currency }} {{ fprice.toFixed(0) }}</h3>
                </div>
              </div>
            </div>
            <div class="availability-currency">
              <div aria-live="polite" role="alert" class="Alert border--default in Alert--info"><span
                  class="SVGIcon SVGIcon--24px Alert__svg inline-svg text-info"><svg data-id="SVG_INFO__24"
                    focusable="false" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                    viewBox="0 0 24 24">
                    <path fill="currentColor" stroke="none" fill-rule="evenodd" d="M11 7.958a1 1 0 112 0 1 1 0 01-2 0z"
                      clip-rule="evenodd"></path>
                    <path stroke="currentColor" d="M12 16.217v-5.113"></path>
                    <path stroke="currentColor" d="M2.75 12a9.25 9.25 0 1118.502.002A9.25 9.25 0 012.75 12z"
                      clip-rule="evenodd">
                    </path>
                  </svg></span>
                <div class="currency-note-content-line"><span class="currency-note-title-wide">You will be charged in
                    {{ $route.params.currency }}.</span>
                </div>
                <p class="currency-note-description"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import API from '@/api';
import axios from 'axios';
import DateRangePicker from 'vue2-daterange-picker';
import moment from 'moment';
import Swal from 'sweetalert2';

export default {
  components: {
    VuePhoneNumberInput,
    DateRangePicker,
  },
  data() {
    return {
      today: new Date(),
      showPicker: false,
      pfprice: null,
      isPopupVisible: false,
      childQuantity: 0,
      petQuantity: 0,
      selectedRegion: '',
      guestNumber: this.$route.params.guestnumber || 0,
      dateRange: { startDate: this.$route.params.startDate, endDate: this.$route.params.endDate },
      format: 'YYYY-MM-DD',
      cardBrands: [],
      minDaysToStay: '',
      regions: {
        NorthAmerica: [
          { name: 'Visa', image: 'https://thefinancialtechnologyreport.com/wp-content/uploads/2023/07/VISA.png' },
          { name: 'MasterCard', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdyDpqeooPZc9BzqB4i1hgvjV6UFSGpu8eQE_PGbr2yqudqSsglPYxHk7n4AZlzJsPv2Y&usqp=CAU' },
          { name: 'American Express', image: 'https://www.pay.nl/hubfs/chub_backup/American-Express-Color.png' }
        ],
        Europe: [
          { name: 'Visa', image: 'https://thefinancialtechnologyreport.com/wp-content/uploads/2023/07/VISA.png' },
          { name: 'MasterCard', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdyDpqeooPZc9BzqB4i1hgvjV6UFSGpu8eQE_PGbr2yqudqSsglPYxHk7n4AZlzJsPv2Y&usqp=CAU' },
          { name: 'Maestro', image: 'https://1000logos.net/wp-content/uploads/2020/07/Maestro-Logo-1992.png' }
        ],
        Asia: [
          { name: 'Visa', image: 'https://thefinancialtechnologyreport.com/wp-content/uploads/2023/07/VISA.png' },
          { name: 'MasterCard', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdyDpqeooPZc9BzqB4i1hgvjV6UFSGpu8eQE_PGbr2yqudqSsglPYxHk7n4AZlzJsPv2Y&usqp=CAU' },
          { name: 'JCB', image: 'https://i0.wp.com/mid-east.info/wp-content/uploads/2022/12/jcb.png?fit=745%2C410&ssl=1' },
          { name: 'UnionPay', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR75V7M-jzFROKn_CaNESS7gmAXhGTH0MIslvuC_VHyynhIGwmScOyTkyhkfjifUJiAFB0&usqp=CAU' }
        ],
        SouthAmerica: [
          { name: 'Visa', image: 'https://thefinancialtechnologyreport.com/wp-content/uploads/2023/07/VISA.png' },
          { name: 'MasterCard', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdyDpqeooPZc9BzqB4i1hgvjV6UFSGpu8eQE_PGbr2yqudqSsglPYxHk7n4AZlzJsPv2Y&usqp=CAU' },
          { name: 'American Express', image: 'https://www.pay.nl/hubfs/chub_backup/American-Express-Color.png' }
        ],
        Africa: [
          { name: 'Visa', image: 'https://thefinancialtechnologyreport.com/wp-content/uploads/2023/07/VISA.png' },
          { name: 'MasterCard', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdyDpqeooPZc9BzqB4i1hgvjV6UFSGpu8eQE_PGbr2yqudqSsglPYxHk7n4AZlzJsPv2Y&usqp=CAU' }
        ]
      },
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      country: '',
      zip_code: '',
      state: '',
      property: [],
      slide: [],
      photos: [],
      cardHolderName: '',
      paymentError: null,
      stripe: null,
      cardElement: null,
      paymentData: {},
      yourValue: '',  // Bind this to your phone number input
      hostfees: '',
      taxfee: '',
      servicefee: '',
      fprice: '',
      sdate: this.formatDate(this.$route.params.startDate),
      edate: this.formatDate(this.$route.params.endDate),
      tprice: this.$route.params.totalPrice1,
      ppday: this.$route.params.days,
    };
  },
  mounted() {
    this.updateCardBrands();
    this.newfunction();
    this.initializeStripe();
    const propertyId = sessionStorage.getItem('propertyId');
    this.getProperty(propertyId);
  },
  watch: {
    dateRange: {
      deep: true,

      handler(newVal, oldVal) {
        if (newVal === null) {
          return;
        }
        const startMoment = moment(newVal.startDate);
        this.sdate = this.formatDate(startMoment);
        const endMoment = moment(newVal.endDate);
        this.edate = this.formatDate(endMoment);
        const minDaystoStay = this.property.min_guests || 1;
        const differenceInDays = endMoment.diff(startMoment, 'days');
        if (differenceInDays < minDaystoStay) {
          Swal.fire({
            type: 'error',
            title: this.$t('Error'),
            text: this.$t('Minimum stay for this property is ' + minDaystoStay + ' days.'),
            confirmButtonText: this.$t('sweetalert.OK'),
            icon: 'error'
          });

          // Reset the date range
          // this.dateRange.startDate = null;
          // this.dateRange.endDate = null;
          return;
        }
        this.tprice = this.$route.params.pfprice * differenceInDays;
        this.ppday = differenceInDays;
        this.newfunction();
      }

    }
  },
  methods: {
    updateCardBrands() {
      fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => {
          fetch('http://ip-api.com/json/' + data.ip)
            .then(response => response.json())
            .then(data => {
              let region = data.timezone.split('/')[0];
              this.cardBrands = this.regions[region] || [];
              this.cardBrands = this.regions[region];
              console.log(this.cardBrands);
            })
            .catch(error => {
              this.cardBrands = this.regions['Europe'];
              console.error('Error fetching IP details:', error);
            });
        })
        .catch(error => {
          this.cardBrands = this.regions['Europe'];
          console.error('Error fetching IP address:', error);
        });
      this.selectedRegion = 'europe '
      this.cardBrands = this.regions[this.selectedRegion] || [];
      this.cardBrands = this.regions[this.selectedRegion];
    },
    togglePopup() {
      this.isPopupVisible = !this.isPopupVisible;
    },
    increment() {
      this.guestNumber++;
      if (this.$route.params.pfprice != 0) {
        const tprice = this.$route.params.pfprice; 
        // console.log("T price:", tprice);
        const totalguests = this.$route.params.guestnumber;
        // console.log("T Guests:",totalguests);
        const perperson = tprice / totalguests;
        const finalprice = perperson * this.guestNumber;
        this.$route.params.pfprice = finalprice;
        this.tprice = finalprice;
        this.newfunction();
      } else {
        const result = this.$route.params.pfprice / this.orginalguest;
        const fresult = result * this.guestNumber
        this.tprice = fresult;
      }
      // this.property.max_guests++;
    },
    decrement() {
      if (this.guestNumber > 0) {
        this.guestNumber--;
        if (this.$route.params.pfprice != 0) {
          const tprice = this.$route.params.pfprice;
          const totalguests = this.$route.params.guestnumber;
          const perperson = tprice / totalguests;
          const finalprice = perperson * this.guestNumber;
          this.$route.params.pfprice = finalprice;
          this.tprice = finalprice; 
          this.newfunction();
        } else {
          const result = this.pffprice / this.orginalguest;
          const fresult = result * this.guestNumber
          this.pfprice = fresult;
        }
      }
    },
    childIncrement() {
      this.childQuantity++;
    },
    childDecrement() {
      if (this.childQuantity > 0) {
        this.childQuantity--;
      }
    },
    petIncrement() {
      this.petQuantity++;
    },
    petDecrement() {
      if (this.petQuantity > 0) {
        this.petQuantity--;
      }
    },
    fetchCurrency() {
      let cur = localStorage.getItem('currency')
      if (cur) {
        this.currency = cur
        setTimeout(() => {

          this.covertcurency(this.pprice.price, this.pprice.currency, this.currency, this.pprice.discountedPrice);
        }, 2000);
      } else {
        axios.get('https://ipapi.co/currency/')
          .then(response => {
            this.currency = response.data;
            setTimeout(() => {
              this.covertcurency(this.pprice.price, this.pprice.currency, this.currency, this.pprice.discountedPrice);
            }, 2000);

          })
          .catch(error => {
            this.currency = 'USD'
            setTimeout(() => {
              this.covertcurency(this.pprice.price, this.pprice.currency, 'USD', this.pprice.discountedPrice);
            }, 2000);
            // console.error('Error fetching currency:', error);
          });
      }
    },
    covertcurency(price, currency, ipCurrency, dCurrency) {
      fetch('https://api.exchangerate-api.com/v4/latest/' + currency)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          if (dCurrency) {
            const iprate = data.rates[ipCurrency];
            let converteddPrice = iprate * dCurrency;
            let formatteddPrice = converteddPrice.toFixed(0);
            this.pfDisprice = formatteddPrice;
            this.pffDisprice = formatteddPrice;
          }
          const iprate = data.rates[ipCurrency];
          let convertedPrice = iprate * price;
          let formattedPrice = convertedPrice.toFixed(0);
          this.pfprice = formattedPrice;
          this.pffprice = formattedPrice;

        })
        .catch(error => {
          console.error('Error fetching currency:', error);
        });
    },
    newfunction() {
      const totalPriceParam = parseFloat(this.tprice) || 0;
      this.hostFees = totalPriceParam * 0.10;
      this.taxfee = totalPriceParam * 0.15;
      this.servicefee = totalPriceParam * 0.5;
      this.fprice = totalPriceParam + this.hostFees + this.taxfee + this.servicefee;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = {
        month: 'short',
        day: 'numeric',
      };
      return new Intl.DateTimeFormat('en-US', options).format(date).toUpperCase();
    },
    onSlideStart(index) {
      this.slide[index] = 0;
    },
    onSlideEnd(index) {
      this.slide[index] = this.prperties[index].photos.length - 1;
    },
    getProperty(propertyId) {
      const originalUrl = this.$route.fullPath;
      API.getProperty(propertyId,
        (res) => {
          this.property = res.data.data;
          this.photos = res.data.data.photos;
        },
        (err) => {
          console.error('Error fetching property:', err);
        }
      );
    },
    async initializeStripe() {
      this.stripe = await loadStripe('pk_test_51OyekLCXJKLOUyW09E6TN6dhstDRI7QjSkQUxuuCFKVROG4pTfOEHeXj6rDw5sn6hf0Z6pDa10YnkzHL9Dt1NUgW00N8hBhFYl');
      const elements = this.stripe.elements();
      this.cardElement = elements.create('card', {
        'disableLink': true
      });
      this.cardElement.mount('#cardElement');
    },
    async processPayment() {
      const { token, error } = await this.stripe.createToken(this.cardElement, {
        name: this.cardHolderName
      });

      if (error) {
        this.paymentError = error.message;
      } else {
        this.cardHolderName = '';
        this.paymentError = null;
        const propertyId = sessionStorage.getItem('propertyId');
        const startDate = this.$route.params.startDate;
        const endDate = this.$route.params.endDate;
        const totalPrice = this.$route.params.totalPrice;
        this.paymentData = {
          propertyId: propertyId,
          token: token,
          startDate: startDate,
          endDate: endDate,
          totalPrice: this.fprice,
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          phone: this.phone,
          country: this.country,
          state: this.state,
          zip_code: this.zip_code,
        };

        API.savePayment(this.paymentData, data => {
          if (data.status === 200) {
            this.$swal({
              type: 'success',
              title: this.$t('ratings_and_reviews.success'),
              text: this.$t('ratings_and_reviews.p_success'),
            }).then(() => {
              this.$router.push({ name: 'home' });
            });
          } else {
            this.$swal({
              type: 'error',
              title: 'Oops...',
              text: data.message,
            });
          }
          this.isDisabled = false;
        }, this.errorCB);
      }
    }
  }
};
</script>


<style scoped>
/* Add any custom styles here */
.act-fast {
  display: inline-block;
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #DFE0E4;
  border-radius: 8px;
  margin-bottom: 4px;
  padding: 25px;
}

:deep(.vue-phone-number-input input) {
  border-color: #676A7D !important;
  border-radius: 35px 0 0 35px !important;
  height: 45px !important;
}

:deep(.input-tel.input-phone-number input) {
  border-radius: 0 35px 35px 0 !important;
}

.act-fast-wrapper {
  margin-bottom: 16px;
}

.checkout-step .form-group label,
.country-selector__label {
  display: none !important;
  padding: 0;
}

#cardElement {
  border: 1px solid #737687;
  padding: 10px;
  border-radius: 30px;
  padding-top: 15px;
}

div#paymentForm div#cardElement {
  margin-bottom: 20px;
}

div#paymentForm button.btn {
  width: 100%;
  font-size: 18px !important;
}

.container {
  max-width: 1172px;
}

.payment-layout {
  height: 100%;
  display: flex;
  padding-top: 20px;
  justify-content: center;
  align-items: flex-start !important;
}

.action button.add-to-cart {
  width: 100%;
  max-width: 150px;
  border-radius: 10px;
  padding: 15px 0;
}

span.SVGIcon {
  margin-right: 8px;
  vertical-align: middle;
}

.main-heading {
  margin-bottom: 16px;
}

.payment-layout h1 {
  width: 100%;
  font-size: 25px;
  margin-bottom: 20px;
}

.step-section {
  display: inline-block;
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #DFE0E4;
  border-radius: 8px;
}

.step-section .step-section__wrapper {
  margin: 25px;
}

.step-section .step-section__wrapper h3 {
  font-size: 16px;
  margin-bottom: 16px;
  line-height: 1.625rem;
  font-weight: 700;
  color: #292929;
}

.mandatory-field-info {
  margin: 0 0.5rem 1rem 0;
  color: #191E3B;
  font-size: 14px;
}

.message-to-host span {
  font-size: 14px;
}

.message-to-host>.premier-host-badge {
  margin: 16px 0;
}

.premier-host-badge {
  border: 0.5px solid #908F8F;
  border-radius: 58px;
  font-size: 0.75rem;
  line-height: 16px;
  padding: 1px 8px;
  margin-top: 5px;
  width: fit-content;
}

.mandatory-field-info:before {
  content: "*";
  color: #A7183C;
  padding-right: 0.25rem;
}

.checkout-step-section .row {
  margin-left: -4px;
  margin-right: -4px;
}

.checkout-step-section .col-xs-6,
.checkout-step-section .col-lg-6,
.checkout-step-section .col-xs-12 {
  padding-left: 4px;
  padding-right: 4px;
}

.checkout-step .form-group {
  margin-bottom: 8px;
}

.form-group.form-floating,
.form-group.form-floating .input-group-wrapper {
  position: relative;
}

.form-floating {
  display: block;
  z-index: 0;
}

input:not(:-webkit-autofill) {
  animation: onAutoFillCancel 0s;
}

.form-control {
  display: block;
  width: 100%;
  height: 46px;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5rem;
  color: #292929;
  background-color: #fff;
  background-image: none;
  border: 1px solid #676A7D;
  border-radius: 30px;
  box-shadow: none;
  outline: none;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control:focus,
.form-control:hover {
  border: 1px solid #70d3ff;
}

.form-control::placeholder {
  color: #676A7D;
}

label {
  display: inline-block;
  margin-bottom: 5px;
}

.mandatory-field-indicator label::after {
  content: " *";
  color: #A7183C;
}



.form-group.form-floating label {
  z-index: 3;
  transition: all 50ms ease-out;
  position: absolute;
  left: 16px;
  font-size: 16px;
  top: 20px;
  pointer-events: none;
  max-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 24px);
  white-space: nowrap;
}

.form-group.form-floating .form-control:focus {
  /* padding-bottom: inherit; */
  border-color: #245abc;
  box-shadow: inset 0 0 0 1px #245abc;
}

.form-group.form-floating .form-control:focus+label {
  font-size: 12px;
  top: 5px;
}

.form-group.form-floating.not-empty label {
  font-size: 12px;
  top: 5px;
  left: 16px;
}

.help-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4px 0 8px;
  line-height: 16px;
  color: #292929;
  font-size: 12px;
}

.has-error .help-block {
  color: #d43626;
  position: relative;
}

span.SVGIcon.cko-validation-checkmark {
  position: absolute;
  top: 18px;
  right: 16px;
  z-index: 2;
}

.contact-details-container-outer__agreement,
.contact-details-container-outer__marketing {
  margin: 24px 0;
  color: #191E3B;
  font-size: 14px;
}

div#paymentForm .cta-panel button.cta-button {
  width: 50%;
  background-color: #70d3ff !important;
  white-space: normal;
  min-height: 56px;
  border-color: #70d3ff;
  position: relative;
  border-radius: 100px !important;
}

.cta-panel {
  padding-top: 0;
  margin-top: 40px;
}

div#paymentForm .cta-panel button.cta-button:hover {
  background-color: #fff !important;
  border-color: #70d3ff !important;
  color: #fff;
}

.sidebar-container--desktop {
  margin-top: 64px;
  position: relative;
  z-index: initial;
  width: 100%;
}

.sidebar-container--desktop .phone-agent-container {
  background-color: #FFFFFF;
  border: 1px solid #DFE0E4;
  border-radius: 8px;
  margin: 0 0 24px 0;
  padding: 16px;
}

.sidebar-container--desktop .phone-agent-icon,
.sidebar-container--desktop .phone-agent-content {
  display: table-cell;
  vertical-align: middle;
  padding-right: 24px;
}

.sidebar-container--desktop .phone-agent-icon svg,
.sidebar-container--desktop .phone-agent-content svg {
  width: 35px;
  height: 35px;
}

.sidebar-container--desktop .customer-number,
.rental-number,
.registration-number {
  margin-top: 10px;
}

.checkout-step .row {
  margin-left: -4px;
  margin-right: -4px;
}

.checkout-step .col-lg-12,
.checkout-step .col-lg-6,
.checkout-step .col-lg-4,
.checkout-step .col-lg-3,
.checkout-step .col-md-12,
.checkout-step .col-md-6,
.checkout-step .col-md-4,
.checkout-step .col-md-3,
.checkout-step .col-sm-12,
.checkout-step .col-sm-6,
.checkout-step .col-sm-4,
.checkout-step .col-sm-3,
.checkout-step .col-xs-12,
.checkout-step .col-xs-6,
.checkout-step .col-md-2 {
  padding-left: 4px;
  padding-right: 4px;
}

.checkout-step section.step-section:first-of-type {
  margin-bottom: 8px;
}

.message-to-host__form .form-group textarea {
  height: 104px;
  padding: 15px;
}

.sidebar-container .listing-info-container {
  border: 1px solid #DFE0E4;
  border-radius: 8px;
}

.sidebar-container .listing-info-container {
  border: 1px solid #DFE0E4;
  border-radius: 8px;
  background-color: #FFFFFF;
}

.sidebar-container .carousel .carousel-control-prev,
.sidebar-container .carousel .carousel-control-next {
  display: flex !important;
}

.listing-property-info {
  padding: 20px 30px 30px 30px;
  position: relative;
}

.listing-property-info h3 {
  color: #292929;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
}

.listing-property-info {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 300;
}

.availability-boxes {
  margin: 0 30px 30px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
  background-color: #FFFFFF;
  border: 1px solid #908F8F;
  border-radius: 4px;
  position: relative;
}

.payment-calandar .date-picker .vue-daterange-picker .form-control .availability-boxes .box {
  width: 50%;
  padding: 10px 15px;
}

.availability-boxes .box {
  width: 100%;
  padding: 10px 15px;
}

.payment-calandar .date-picker .vue-daterange-picker .form-control .availability-boxes .box:first-child {
  border-right: 1px solid #908F8F;
}

.availability-boxes .box span {
  font-size: 12px;
  line-height: 1.2;
}

.availability-boxes .box p {
  margin: 0;
  font-size: 15px;
  line-height: 1.4;
}

.availability-listing,
.availability-currency {
  margin: 0 30px 30px;
}

.availability-listing .price-listing .lisitng-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.availability-listing .price-listing .lisitng-content span {
  color: #676A7D;
  font-size: 14px;
  line-height: 20px;
}

.availability-listing .lisitng-content.total-pricing {
  border-bottom: 1px solid #DDDDDE;
  border-top: 1px solid #DDDDDE;
  padding: 16px 0;
}

.availability-listing .lisitng-content.total-pricing h3 {
  color: #676A7D;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}

.availability-currency .Alert {
  display: flex;
}

.availability-currency {
  border: 1px solid #DDDDDE;
  padding: 16px;
  border-radius: 5px;
}

.availability-currency .Alert .currency-note-title-wide {
  font-size: 14px;
}

.sidebar-container {
  margin-top: 45px;
}
</style>
<style scoped>
.container {
  padding: 15px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.col-lg-8 {
  flex: 2;
}

.col-lg-4 {
  flex: 1;
}

.guest-header {
  margin: 0;
}

.card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  margin: 0;
}

.card-item {
  margin-left: 10px;
  /* Adjust spacing between images as needed */
}

.card-image {
  max-width: 37px;
  /* Adjust the maximum width as needed */
  height: auto;
}

.payment-calandar {
  padding: 0 30px;
}

.payment-calandar .date-picker .daterangepicker.show-weeknumbers,
.payment-calandar .date-picker .daterangepicker {
  min-width: 486px;
}

.popup-guest {
  border: 1px solid rgb(231 231 232);
  border-radius: .5rem;
  background: white;
  box-shadow: 0 2px .75rem rgba(12, 14, 28, .08);
  display: block;
  max-width: 100%;
  margin-left: auto;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  position: absolute;
  top: 64px;
}

.guest-content {
  display: flex;
  align-items: center;
  border-right: 0 !important;
  justify-content: space-between;
  width: 100%;
}

.guest-content .guest-col h4 {
  margin: 0;
  font-weight: 400;
  font-size: 15px;
}

.guest-content .guest-col .input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group-append,
.input-group-prepend {
  background: #ffffff;
  color: #c9c8c8;
  width: auto;
  border: none;
}

.input-group-prepend {
  margin-right: -1px;
}

.guest-content .form-control {
  padding: 0;
  height: 30px;
  border: 0;
  text-align: center;
  width: 60px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media (max-width: 767px) {
  .availability-boxes .box p {
    font-size: 14px;
  }

  .listing-property-info {
    padding: 20px 15px 30px 15px;
  }

  .payment-calandar {
    padding: 0 15px;
  }

  .availability-boxes,
  .availability-listing,
  .availability-currency {
    margin: 0 15px 30px;
  }
}
</style>